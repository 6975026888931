import React, { useState } from 'react'
import * as FaIcons from "react-icons/fa"
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { SidebarData } from './SidebarData'
import './nav1.css'
import { IconContext } from 'react-icons'
function Navbar() {
    const [sidebar, setSidebar] = useState(false)
    const [title, setTitle] = useState("Jupyter Lab")
    const showSidebar = () => setSidebar(!sidebar)
    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className='navbar'>
                    <Link to="#" className='menu-bars'>
                        <FaIcons.FaBars onClick={showSidebar} />

                    </Link>

                    <span className="title">{title}</span>
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to="#" className='menu-bars'>
                                <AiIcons.AiOutlineClose />

                            </Link>
                        </li>
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        <a
                                            onClick={() => setTitle(item.title)}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </a>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>


                </nav>
            </IconContext.Provider>
        </>
    )
}

const Nav = () => {
    return (
        <Navbar />
    )
}



export default Nav