import React from 'react'
import TitleText from '../utils/TitleText'
const NotFound = () => {
  TitleText('CodeBench - 404 !')
  return (
    <div>
       <h2>
       404 Not Found ! 
       </h2>
        </div>
  )
}

export default NotFound